<template>
  <div class="fullScreen" :class="{ show: showEditDialog }">
    <Crumbs>
      <el-breadcrumb-item slot="after">
        {{ options.title }}
      </el-breadcrumb-item>
    </Crumbs>
    <div class="fullScreenMain" v-loading="loading">
      <div class="fullScreenMainCon">
        <div>
          <el-form ref="form" :model="form" :rules="rules" label-width="120px" class="form_box">
            <el-descriptions border class="margin-top w_100" :column="4" direction="vertical">

              <!-- 发票号码 -->
              <el-descriptions-item labelClassName="invoiceLabelClassName" contentClassName="invoiceContentClassName"
                :span="1">
                <template slot="label">
                  发票号码<span style="color: red;">*</span>
                </template>
                <el-form-item label-width="0px" prop="invoiceNum">
                  <el-input :disabled="options.disabled" v-model="form.invoiceNum" :placeholder="options.disabled ? '' : '请输入发票号码'"></el-input>
                </el-form-item>
              </el-descriptions-item>

              <!-- 开票日期 -->
              <el-descriptions-item labelClassName="invoiceLabelClassName" contentClassName="invoiceContentClassName"
                :span="1">
                <template slot="label">
                  开票日期<span style="color: red;">*</span>
                </template>
                <el-form-item label-width="0px" prop="invoiceDate">
                  <el-date-picker :disabled="options.disabled" v-model="form.invoiceDate" type="date"
                    placeholder="选择日期时间"></el-date-picker>
                </el-form-item>
              </el-descriptions-item>

              <!-- 发票名称 -->
              <el-descriptions-item labelClassName="invoiceLabelClassName" contentClassName="invoiceContentClassName"
                :span="1">
                <template slot="label">
                  发票名称<span style="color: red;">*</span>
                </template>
                <el-form-item label-width="0px" prop="invoiceTypeOrg">
                  <el-input :disabled="options.disabled" v-model="form.invoiceTypeOrg" :placeholder="options.disabled ? '' : '请输入发票名称'"></el-input>
                </el-form-item>
              </el-descriptions-item>

              <!-- 发票代码 -->
              <el-descriptions-item labelClassName="invoiceLabelClassName" contentClassName="invoiceContentClassName"
                :span="1">
                <template slot="label">
                  发票代码
                </template>
                <el-form-item label-width="0px" prop="invoiceCode">
                  <el-input :disabled="options.disabled" v-model="form.invoiceCode" :placeholder="options.disabled ? '' : '请输入发票代码'"></el-input>
                </el-form-item>
              </el-descriptions-item>

              <!-- 购方名称 -->
              <el-descriptions-item labelClassName="invoiceLabelClassName" contentClassName="invoiceContentClassName"
                :span="1">
                <template slot="label">
                  购方名称<span style="color: red;">*</span>
                </template>
                <el-form-item label-width="0px" prop="purchaserName">
                  <el-input :disabled="options.disabled" v-model="form.purchaserName" 
                    :placeholder="options.disabled ? '' : '请输入购方名称'"></el-input>
                </el-form-item>
              </el-descriptions-item>

              <!-- 购方纳税人识别号 -->
              <el-descriptions-item labelClassName="invoiceLabelClassName" contentClassName="invoiceContentClassName"
                :span="1">
                <template slot="label">
                  购方纳税人识别号<span style="color: red;">*</span>
                </template>
                <el-form-item label-width="0px" prop="purchaserRegisterNum">
                  <el-input :disabled="options.disabled" v-model="form.purchaserRegisterNum"
                    :placeholder="options.disabled ? '' : '请输入购方纳税人识别号'"></el-input>
                </el-form-item>
              </el-descriptions-item>

              <!-- 销售方名称 -->
              <el-descriptions-item labelClassName="invoiceLabelClassName" contentClassName="invoiceContentClassName"
                :span="1">
                <template slot="label">
                  销售方名称<span style="color: red;">*</span>
                </template>
                <el-form-item label-width="0px" prop="sellerName">
                  <el-input :disabled="options.disabled" v-model="form.sellerName" 
                    :placeholder="options.disabled ? '' : '请输入销售方名称'"></el-input>
                </el-form-item>
              </el-descriptions-item>

              <!-- 销售方纳税人识别号 -->
              <el-descriptions-item labelClassName="invoiceLabelClassName" contentClassName="invoiceContentClassName"
                :span="1">
                <template slot="label">
                  销售方纳税人识别号<span style="color: red;">*</span>
                </template>
                <el-form-item label-width="0px" prop="sellerRegisterNum">
                  <el-input :disabled="options.disabled" v-model="form.sellerRegisterNum"
                    :placeholder="options.disabled ? '' : '请输入销售方纳税人识别号'"></el-input>
                </el-form-item>
              </el-descriptions-item>

              <!-- 货物名称 -->
              <el-descriptions-item labelClassName="invoiceLabelClassName" contentClassName="invoiceContentClassName"
                :span="1">
                <template slot="label">
                  货物名称<span style="color: red;">*</span>
                </template>
                <el-form-item label-width="0px" prop="commodityName">
                  <el-input :disabled="options.disabled" v-model="form.commodityName" 
                    :placeholder="options.disabled ? '' : '请输入货物名称'"></el-input>
                </el-form-item>
              </el-descriptions-item>

              <!-- 金额 -->
              <el-descriptions-item labelClassName="invoiceLabelClassName" contentClassName="invoiceContentClassName"
                :span="1">
                <template slot="label">
                  金额<span style="color: red;">*</span>
                </template>
                <el-form-item label-width="0px" prop="commodityAmount">
                  <el-input-number :disabled="options.disabled" v-model="form.commodityAmount" :min="0"
                    controls-position="right"></el-input-number>
                </el-form-item>
              </el-descriptions-item>

              <!-- 税率 -->
              <el-descriptions-item labelClassName="invoiceLabelClassName" contentClassName="invoiceContentClassName"
                :span="1">
                <template slot="label">
                  税率<span style="color: red;">*</span>
                </template>
                <el-form-item label-width="0px" prop="commodityTaxRate">
                  <el-input 
                    :disabled="options.disabled" 
                    v-model="form.commodityTaxRate"
                    @input="handleTaxRateInput"
                    :placeholder="options.disabled ? '' : '请输入税率'">
                  </el-input>
                </el-form-item>
              </el-descriptions-item>

              <!-- 税额 -->
              <el-descriptions-item labelClassName="invoiceLabelClassName" contentClassName="invoiceContentClassName"
                :span="1">
                <template slot="label">
                  税额<span style="color: red;">*</span>
                </template>
                <el-form-item label-width="0px" prop="commodityTax">
                  <el-input-number :disabled="options.disabled" v-model="form.commodityTax" :min="0"
                    controls-position="right"></el-input-number>
                </el-form-item>
              </el-descriptions-item>

              <!-- 增值税发票的细分类型 -->
              <el-descriptions-item labelClassName="invoiceLabelClassName" contentClassName="invoiceContentClassName"
                :span="1">
                <template slot="label">
                  增值税发票的细分类型<span style="color: red;">*</span>
                </template>
                <el-form-item label-width="0px" prop="invoiceTag">
                  <el-input :disabled="options.disabled" v-model="form.invoiceTag"
                    :placeholder="options.disabled ? '' : '请输入增值税发票的细分类型'"></el-input>
                </el-form-item>
              </el-descriptions-item>

              <!-- 价税合计(小写) -->
              <el-descriptions-item labelClassName="invoiceLabelClassName" contentClassName="invoiceContentClassName"
                :span="1">
                <template slot="label">
                  价税合计(小写)<span style="color: red;">*</span>
                </template>
                <el-form-item label-width="0px" prop="amountInFiguers">
                  <el-input-number :disabled="options.disabled" v-model="form.amountInFiguers" :min="0"
                    controls-position="right"></el-input-number>
                </el-form-item>
              </el-descriptions-item>

              <!-- 合计金额 -->
              <el-descriptions-item labelClassName="invoiceLabelClassName" contentClassName="invoiceContentClassName"
                :span="1">
                <template slot="label">
                  合计金额<span style="color: red;">*</span>
                </template>
                <el-form-item label-width="0px" prop="totalAmount">
                  <el-input-number :disabled="options.disabled" v-model="form.totalAmount" :min="0"
                    controls-position="right"></el-input-number>
                </el-form-item>
              </el-descriptions-item>

              <!-- 合计税额 -->
              <el-descriptions-item labelClassName="invoiceLabelClassName" contentClassName="invoiceContentClassName"
                :span="1">
                <template slot="label">
                  合计税额<span style="color: red;">*</span>
                </template>
                <el-form-item label-width="0px" prop="totalTax">
                  <el-input-number :disabled="options.disabled" v-model="form.totalTax" :min="0"
                    controls-position="right"></el-input-number>
                </el-form-item>
              </el-descriptions-item>

              <!-- 备注 -->
              <el-descriptions-item labelClassName="invoiceLabelClassName" contentClassName="invoiceContentClassName"
                :span="4">
                <template slot="label">
                  备注
                </template>
                <el-form-item label-width="0px" prop="remarks">
                  <el-input type="textarea" :disabled="options.disabled" v-model="form.remarks"
                    :placeholder="options.disabled ? '' : '请输入备注'"></el-input>
                </el-form-item>
              </el-descriptions-item>
            </el-descriptions>
          </el-form>
        </div>
      </div>
      <div class="fullScreenOperation shadow">
        <el-button v-if="!options.disabled" type="success" class="teal_bg" @click="handleSubmit(1)">提交</el-button>
        <el-button type="info" plain @click="re(false)">返回</el-button>
      </div>
    </div>

  </div>
</template>

<script>
import { formLocationError } from '@/util/common'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    OpinionArea: () => import('@/components/OpinionArea.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {
          title: '', //面包屑
          disabled: false, //编辑
          isApply: false, //新增
        }
      },
    },
    getProjectList: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data() {
    // 自定义验证规则
    const validateTaxRate = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入税率'));
      } else {
        // 去除%符号后验证
        const rateNum = value.replace('%', '');
        if (!/^(0|[1-9]\d?)$/.test(rateNum)) {
          callback(new Error('税率必须在0-99%之间'));
        } else {
          callback();
        }
      }
    };

    const validateRegisterNum = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入纳税人识别号'));
      } else if (!/^[0-9A-Z]{15,20}$/.test(value)) {
        callback(new Error('纳税人识别号格式不正确'));
      } else {
        callback();
      }
    };

    return {
      showEditDialog: false, // 组件 隐藏 或 显示
      loading: false, // 组件 加载
      // 申请 表单数据
      form: {
        amountInFiguers: 0,
        commodityAmount: 0,
        commodityName: "",
        commodityTax: 0,
        commodityTaxRate: "",
        invoiceCode: "",
        invoiceDate: "",
        invoiceNum: "",
        invoiceTag: "",
        invoiceTypeOrg: "",
        purchaserName: "",
        purchaserRegisterNum: "",
        remarks: "",
        sellerName: "",
        sellerRegisterNum: "",
        totalAmount: 0,
        totalTax: 0
      },
      history_show: false, //历史开票弹窗
      historyDataList: [],
      dictData: {
        invoiceType: [],
        VatDataAll: [],
        projectSource: [],
        companyType: [],
        invoiceLines: [],
        offsetStatus: [],
        invoiceExpenseType: [],
      },
      rules: {
        amountInFiguers: [{ required: true, message: '请输入价税合计(小写)', trigger: ['blur', 'change'] }],
        commodityAmount: [{ required: true, message: '请输入金额', trigger: ['blur', 'change'] }],
        commodityName: [{ required: true, message: '请输入货物名称', trigger: ['blur', 'change'] }],
        commodityTax: [{ required: true, message: '请输入税额', trigger: ['blur', 'change'] }],
        commodityTaxRate: [{ required: true, validator: validateTaxRate, trigger: ['blur', 'change'] }],
        invoiceDate: [{ required: true, message: '请输入发票日期', trigger: ['blur', 'change'] }],
        invoiceNum: [{ required: true, message: '请输入发票号码', trigger: ['blur', 'change'] },
          { min: 8, max: 20, message: '发票号码必须是8-20位', trigger: ['blur', 'change'] }],
        invoiceTag: [{ required: true, message: '请输入发票类型', trigger: ['blur', 'change'] }],
        invoiceTypeOrg: [{ required: true, message: '请输入发票名称', trigger: ['blur', 'change'] }],
        purchaserName: [{ required: true, message: '请输入购买方名称', trigger: ['blur', 'change'] }],
        purchaserRegisterNum: [{ required: true, validator: validateRegisterNum, trigger: ['blur', 'change'] }],
        sellerName: [{ required: true, message: '请输入销售方名称', trigger: ['blur', 'change'] }],
        sellerRegisterNum: [{ required: true, validator: validateRegisterNum, trigger: ['blur', 'change'] }],
        totalAmount: [{ required: true, message: '请输入合计金额', trigger: ['blur', 'change'] }],
        totalTax: [{ required: true, message: '请输入合计税额', trigger: ['blur', 'change'] }]
      },
    }
  },
  inject: ['re'],
  watch: {
    isShow: {
      immediate: true,
      handler: function (newVal) {
        this.showEditDialog = newVal
      },
    },
    options: {
      deep: true,
      immediate: true,
      handler: function (options) {
        this.form = this.options.deepClone()
        if (options.id) {
          // this.getDetails() 
        } else {
          this.form = {
          }
        }
      },
    },
    isDialog: {
      // immediate: true,
      handler: function (newVal) {
        if (!newVal) {
          this.cancelFn()
        }
      },
    },
    'form.commodityAmount': {
      handler: function(newVal) {
        this.calculateTax();
      }
    },
    'form.commodityTaxRate': {
      handler: function(newVal) {
        this.calculateTax();
      }
    }
  },
  created() {
  },
  computed: {
    amount() {
      let num = 0
      if (this.form.invoiceApplicationDetailList) {
        this.form.invoiceApplicationDetailList.forEach(v => {
          if (v.invoiceAmount) {
            num += Number(v.invoiceAmount)
          }
        })
      }
      num = num.toFixed(2)
      return num
    },
    viewInvoiceId() {
      let id = ''
      if (this.form.invoiceOffse) {
        id = this.form.invoiceOffse.id
      } else {
        if (this.form.invoiceLines === 'OFFSET' && this.offsetId) {
          id = this.offsetId
        }
      }
      return id
    },
  },
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return n
      }
    },
    transitionType(val, arr) {
      let str = ''
      if (arr && arr.length > 0 && val) {
        const item = arr.find(v => v.vatType == val)
        str = item?.vatName
      }
      return str
    },
    transitionData(val, arr) {
      let str = ''
      if (arr && arr.length > 0 && val) {
        const item = arr.find(v => v.vatData == val)
        str = item?.vatName
      }
      return str
    },
  },
  methods: {
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          // 提交前再次验证金额计算
          if (this.form.amountInFiguers !== (this.form.totalAmount + this.form.totalTax)) {
            this.$message.error('金额计算有误，请检查');
            return;
          }
          
          this.loading = true;
          this.$api.invoice.addInvoice(this.form).then(res => {
            this.$message.success('提交成功');
            this.loading = false;
            this.re(true);
          }).catch(err => {
            console.log(err);
            this.loading = false;
          });
        } else {
          this.$message.warning('请填写完整所有必填项');
        }
      });
    },
    // 计算税额和总额
    calculateTax() {
      if (this.form.commodityAmount && this.form.commodityTaxRate) {
        // 去除%符号并转换为数字
        const rateStr = this.form.commodityTaxRate.replace('%', '');
        const taxRate = parseFloat(rateStr) / 100;
        
        // 计算税额
        this.form.commodityTax = parseFloat((this.form.commodityAmount * taxRate).toFixed(2));
        
        // 计算价税合计
        this.form.amountInFiguers = parseFloat((this.form.commodityAmount + this.form.commodityTax).toFixed(2));
        
        // 更新合计金额和合计税额
        this.form.totalAmount = this.form.commodityAmount;
        this.form.totalTax = this.form.commodityTax;
      }
    },
    // 处理税率输入,自动添加%符号
    handleTaxRateInput() {
      if (this.form.commodityTaxRate) {
        let value = this.form.commodityTaxRate.replace('%', '');
        if (value) {
          this.form.commodityTaxRate = value + '%';
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.fullScreenMainCon {
  overflow: hidden;
  padding: 25px 0;
}

.form_box {
  .danwei {
    position: absolute;
    right: 2%;
  }

  .aiw {
    width: calc(100% - 33.3333%);
  }

  .amount {
    display: inline-block;
    width: 100%;
    height: 40px;
    color: #c0c4cc;
    background-color: #f5f7fa;
    padding: 0 10px;
    border: 1px solid #e4e7ed;
    border-radius: 4px;
  }
}

.ticket_box {
  margin-top: 16px;

  .ticket_div {
    position: relative;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-top: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;

    span {
      display: inline-block;
      margin: 0 4px;
      text-decoration: underline;
    }

    .section {
      width: 60px;
      text-align: center;
    }

    .lineBtn {
      float: right;
      margin-top: 10px;
    }

    .jilvBtn {
      float: right;
      margin-left: 8px;
      margin-top: 10px;
    }
  }
}

.el-divider__text.is-left {
  font-size: 18px;
}

.margin-top {
  margin-top: 10px;
}

.w_100 {
  width: 100%;
}

.el-form-item {
  margin-bottom: 10px;
  padding: 0;
}

.el-descriptions .el-descriptions-item__cell {
  padding-bottom: 7px;
}

/deep/.invoiceLabelClassName {
  width: 12.5%;
}

/deep/.invoiceContentClassName {
  height: 75px !important;
}

// input type为number，只输入数字
/deep/.numrule input::-webkit-outer-spin-button,
/deep/.numrule input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/.numrule input[type='number'] {
  -moz-appearance: textfield;
}

.invoice-file-text {
  display: inline-block;
  width: 300px;
}

/deep/.el-input.is-disabled .el-input__inner {
  background-color: #f5f7fa !important;
  border-color: #e4e7ed !important;
  color: #c0c4cc !important;
  cursor: not-allowed !important;
}
</style>
